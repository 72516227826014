<template>
  <div>
    <b-row>
      <b-col class="text-right mb-1">
        <router-link to="/unit-category/add">
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="primary"
            pill
            class="px-3"
          >
            Tambah Kategori
          </b-button>
        </router-link>
      </b-col>
    </b-row>
    <b-card>
      <!-- search input -->
      <div class="custom-search d-flex justify-content-end">
        <b-form-group>
          <div class="d-flex align-items-center">
            <label class="mr-1">Cari</label>
            <b-form-input
              v-model="searchTerm"
              placeholder="Cari"
              type="text"
              class="d-inline-block"
            />
          </div>
        </b-form-group>
      </div>

      <!-- table -->
      <vue-good-table
        :columns="columns"
        :rows="rows"
        :rtl="direction"
        :search-options="{
          enabled: true,
          externalQuery: searchTerm,
        }"
        :select-options="{
          enabled: false,
          selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
          selectionInfoClass: 'custom-class',
          selectionText: 'rows selected',
          clearSelectionText: 'clear',
          disableSelectInfo: true, // disable the select info panel on top
          selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
        }"
        :pagination-options="{
          enabled: true,
          perPage: pageLength,
        }"
      >
        <template slot="table-row" slot-scope="props">
          <!-- Column: Name -->
          <span v-if="props.column.field === 'name'" class="text-nowrap">
            <!-- <b-avatar
                            :src="props.row.avatar"
                            class="mx-1"
                        /> -->
            <span class="text-nowrap">{{ props.row.name }}</span>
          </span>

          <!-- Column: Name -->
          <span
            v-else-if="props.column.field === 'parent_name'"
            class="text-nowrap"
          >
            <span class="text-nowrap" v-if="props.row.parent_name === ''"
              >-</span
            >
            <span class="text-nowrap" v-else>{{ props.row.parent_name }}</span>
          </span>

          <!-- Column: Action -->
          <span v-else-if="props.column.field === 'action'">
            <span>
              <b-dropdown
                variant="link"
                toggle-class="text-decoration-none"
                no-caret
              >
                <template v-slot:button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="text-body align-middle mr-25"
                  />
                </template>
                <b-dropdown-item
                  v-bind:to="{ path: '/unit-category/' + props.row.id }"
                >
                  <feather-icon icon="Edit2Icon" class="mr-50" />
                  <span>Detail</span>
                </b-dropdown-item>
                <b-dropdown-item @click="deleteCategories(props.row.id)">
                  <feather-icon icon="TrashIcon" class="mr-50" />
                  <span>Hapus</span>
                </b-dropdown-item>
              </b-dropdown>
            </span>
          </span>

          <!-- Column: Common -->
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>

        <!-- pagination -->
        <template slot="pagination-bottom" slot-scope="props">
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap"> 1 sampai </span>
              <b-form-select
                v-model="pageLength"
                :options="['3', '5', '10']"
                class="mx-1"
                @input="
                  (value) => props.perPageChanged({ currentPerPage: value })
                "
              />
              <span class="text-nowrap"> dari {{ props.total }} data </span>
            </div>
            <div>
              <b-pagination
                :value="1"
                :total-rows="props.total"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="(value) => props.pageChanged({ currentPage: value })"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>
    </b-card>
  </div>
</template>

<script>
import {
  BButton,
  BRow,
  BCol,
  BCard,
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdown,
  BDropdownItem,
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import store from "@/store/index";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    VueGoodTable,
    ToastificationContent,
    BButton,
    BRow,
    BCol,
    BCard,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      pageLength: 5,
      dir: false,
      columns: [
        {
          label: "Nama",
          field: "name",
        },
        {
          label: "Kategori Induk",
          field: "parent_name",
        },
        {
          label: "",
          field: "action",
          width: "50px",
        },
      ],
      permissions: [],
      rows: [],
      errors: "",
      errMessage: "",
      searchTerm: "",
    };
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true;
        return this.dir;
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false;
      return this.dir;
    },
  },
  created() {
    this.getAllPermissions();
    this.getDatasInquiry();
  },
  methods: {
    getAllPermissions() {
      this.permissions = sessionStorage.getItem("permissions");
    },
    getDatasInquiry() {
      this.$http
        .get("unit/categories")
        .then((res) => {
          this.rows = res.data.data;
          // console.log(res.data.data)
        })
        .catch((errors) => {
          this.errMessage = errors.response.data.message;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Notifikasi",
              icon: "BellIcon",
              text: this.errMessage,
              variant: "warning",
            },
          });
          console.log(errors.response);
        });
    },
    deleteCategories($id) {
      this.$http
        .post("unit/category/" + $id + "/delete")
        .then((response) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Notifikasi",
              icon: "BellIcon",
              text: "Kategori berhasil dihapus",
              variant: "danger",
            },
          });
          location.href = "/unit/categories";
          // console.log(response.data.data)
        })
        .catch((errors) => {
          this.errMessage = errors.response.data.message;
          console.log(errors.response);
        });
    },
  },
};
</script>

<style lang="scss" >
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>